import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: `history`,
  routes: [
    {
      path: `/trainer`,
      name: `home`,
      component: () =>
        import(/* webpackChunkName: "trainer" */ `./views/Home.vue`),
    },
    {
      path: `/about`,
      name: `about`,
      component: () =>
        import(/* webpackChunkName: "about" */ `./views/About.vue`),
    },
    {
      path: `/imprint`,
      name: `imprint`,
      component: () =>
        import(/* webpackChunkName: "imprint" */ `./views/Imprint.vue`),
    },
    {
      path: `/index.html`,
      redirect: `/trainer`,
      replace: true,
    },
    {
      path: `/`,
      redirect: `/trainer`,
      replace: true,
    },
    {
      // will match everything
      path: `*`,
      redirect: `/trainer`,
      replace: true,
    },
  ],
})
