import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import '@/registerServiceWorker'

if (!window.umami) window.umami = console.log
if (localStorage.getItem(`nomami`)) window.umami = () => {}
Vue.config.productionTip = !PROD

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount(`#app`)
